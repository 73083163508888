import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db } from './firebaseConfig';
import './AdminPage.css'; // Import the CSS file for styling

const AdminPage = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [currentItem, setCurrentItem] = useState({
    englishName: '',
    arabicName: '',
    price: '',
    description: '', // Optional field
    englishAdditionalInfo: '', // Optional field
    arabicAdditionalInfo: '', // Optional field
    menu: '',
    category: ''
  });
  const [categories, setCategories] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/signin');  // Redirect to sign-in page after logout
    }).catch((error) => {
      console.error('Error signing out:', error);
    });
  };

  useEffect(() => {
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        handleLogout();
      }, 20 * 60 * 1000); // 20 minutes in milliseconds
    };

    // Reset timer on user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('scroll', resetTimer);
    window.addEventListener('click', resetTimer);

    resetTimer(); // Initialize the timer on component mount

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('scroll', resetTimer);
      window.removeEventListener('click', resetTimer);
    };
  }, []);

  // Fetch menu items from Firestore
  useEffect(() => {
    fetchMenuItems();
  }, []);

  const fetchMenuItems = async () => {
    const querySnapshot = await getDocs(collection(db, 'menuItems'));
    const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setMenuItems(items);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem({ ...currentItem, [name]: value });

    if (name === 'menu') {
      updateCategories(value);
      setCurrentItem({ ...currentItem, menu: value, category: '' });
    }
  };

  const updateCategories = (menuValue) => {
    let categoriesList = [];
    if (menuValue === 'Western sweets') {
      categoriesList = ['Starter menu 1', 'Starter menu 2', 'Tarts', 'Cake', 'Mousses 1', 'Mousses 2', 'Mousses 3', 'Ice cream'];
    } else if (menuValue === 'Drinks') {
      categoriesList = [
        'Starter menu', 'Fresh cocktails 1', 'Fresh cocktails 2', 'Fresh cocktails 3', 'Fresh cocktails 4', 'Soft drinks', 'Cold drinks', 'Frappe',
        'Milk shakes', 'Fresh juices', 'Mojitos', 'RedBull With Flavors',
        'Coffee Drinks 1', 'Coffee Drinks 2', 'Flavored Coffee Drinks', 'Specialty & Chocolate Drinks',
        'Tea & Herbal Drinks'
      ];
    } else if (menuValue === 'Arabic sweets') {
      // Add categories Row 1 to Row 9 for Arabic sweets
      categoriesList = ['Row 1', 'Row 2', 'Row 3', 'Row 4', 'Row 5', 'Row 6', 'Row 7', 'Row 8', 'Row 9'];
    }
    setCategories(categoriesList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editing) {
      // Update existing item
      const itemDoc = doc(db, 'menuItems', editingId);
      await updateDoc(itemDoc, currentItem);
      setEditing(false);
      setEditingId(null);
    } else {
      // Add new item
      await addDoc(collection(db, 'menuItems'), { ...currentItem, price: parseFloat(currentItem.price) });
    }
    setCurrentItem({ englishName: '', arabicName: '', price: '', description: '', englishAdditionalInfo: '', arabicAdditionalInfo: '', menu: '', category: '' });
    fetchMenuItems();
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    updateCategories(item.menu);
    setEditing(true);
    setEditingId(item.id);
  };

  const handleDelete = async (id) => {
    const itemDoc = doc(db, 'menuItems', id);
    await deleteDoc(itemDoc);
    fetchMenuItems();
  };

  return (
    <div className="admin-container">
      <h2>Admin Page</h2>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="form-group">
          <label>English Name:</label>
          <input
            type="text"
            name="englishName"
            value={currentItem.englishName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Arabic Name:</label>
          <input
            type="text"
            name="arabicName"
            value={currentItem.arabicName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Price:</label>
          <input
            type="number"
            name="price"
            value={currentItem.price}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Description:</label>
          <textarea
            name="description"
            value={currentItem.description}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>English Additional Info:</label>
          <input
            type="text"
            name="englishAdditionalInfo"
            value={currentItem.englishAdditionalInfo}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Arabic Additional Info:</label>
          <input
            type="text"
            name="arabicAdditionalInfo"
            value={currentItem.arabicAdditionalInfo}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Menu:</label>
          <select
            name="menu"
            value={currentItem.menu}
            onChange={handleChange}
            required
          >
            <option value="">Select a menu</option>
            <option value="Western sweets">Western sweets</option>
            <option value="Arabic sweets">Arabic sweets</option>
            <option value="Drinks">Drinks</option>
          </select>
        </div>
        {categories.length > 0 && (
          <div className="form-group">
            <label>Category:</label>
            <select
              name="category"
              value={currentItem.category}
              onChange={handleChange}
              required
            >
              <option value="">Select a category</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
          </div>
        )}
        <button type="submit" className="submit-button">{editing ? 'Update' : 'Add'} Item</button>
      </form>

      <table className="items-table">
        <thead>
          <tr>
            <th>English Name</th>
            <th>Arabic Name</th>
            <th>Price</th>
            <th>Menu</th>
            <th>Category</th>
            <th>Description</th>
            <th>English Additional Info</th>
            <th>Arabic Additional Info</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {menuItems.map((item) => (
            <tr key={item.id}>
              <td>{item.englishName}</td>
              <td>{item.arabicName}</td>
              <td>{item.price} JOD</td>
              <td>{item.menu}</td>
              <td>{item.category || 'No Category'}</td>
              <td>{item.description || 'No Description'}</td>
              <td>{item.englishAdditionalInfo || 'No Additional Info'}</td>
              <td>{item.arabicAdditionalInfo || 'No Additional Info'}</td>
              <td>
                <button className="edit-button" onClick={() => handleEdit(item)}>Edit</button>
                <button className="delete-button" onClick={() => handleDelete(item.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPage;
