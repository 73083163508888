import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AdminPage from './AdminPage';
import SignInPage from './SignInPage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from 'react';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={user ? <Navigate to="/admin" /> : <SignInPage />}
        />
        <Route
          path="/signin"
          element={user ? <Navigate to="/admin" /> : <SignInPage />}
        />
        <Route
          path="/admin"
          element={user ? <AdminPage /> : <Navigate to="/signin" />}
        />
      </Routes>
    </Router>
  );
}

export default App;
